import React, {Component, Fragment} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons/faMapMarkedAlt";
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook";
import {faTwitter} from "@fortawesome/free-brands-svg-icons/faTwitter";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {Helmet} from "react-helmet";

class Contacto extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Contacto | Colegio de Pediatras de Querétaro A.C.</title>
                </Helmet>
                <div id="contacto" className="mt-10 lg:mt-16 mb-10">
                    <section className="container w-full mx-auto py-5 px-1 lg:px-5">
                        <div className="text-center font-bold text-3xl flex justify-center">
                            <div className="w-full lg:w-1/2 h-36">
                                <h2 className="section-title avenir dark:text-gray-200" data-aos="fade-up">Contáctanos</h2>
                            </div>
                        </div>
                        <div
                            className="w-11/12 mx-auto py-4 px-4 lg:py-8 lg:px-12 my-10 text-justify flex flex-col lg:flex-row dark:text-gray-300">
                            <div className="w-full lg:w-1/2 flex flex-wrap content-center py-2 px-3" data-aos="fade-right" data-aos-delay="50">
                                <div>
                                    <h3 className="font-bold text-lg">Datos bancarios</h3>
                                    <br/>
                                    <p>
                                        RFC: <b>CPQ1901178Y2</b><br/><br/>
                                        Colegio de Pediatras de Querétaro AC<br/>
                                        <b>Cuenta de cheques</b> BANAMEX<br/>
                                        <b>Sucursal</b> 0917 TEC- 100<br/>
                                        <b>Número de cuenta</b><br/>
                                        7014-1314643<br/>
                                        <b>Clabe interbancaria</b><br/>
                                        002680701413146430<br/><br/>
                                        ENVIAR RECIBO DE TRANSFERENCIA ELECTRONICA O BANCO<br/>
                                        <a href="mailto:tesoreria@colegiodepediatrasdequeretaro.com.mx">contacto@colegiodepediatrasdequeretaro.com</a>
                                    </p>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2 flex flex-wrap content-center py-2 px-3 relative text-lg mt-20 lg:mt-0">
                                <div
                                    className="dotted-grid-blue w-96 h-96 absolute -bottom-16 -right-16 z-10 hidden xl:block" data-aos="zoom-in-left">
                                </div>
                                <div className="bg-gray-100 dark:bg-gray-800 w-full p-8 rounded-lg shadow-md z-20" data-aos="fade-left" data-aos-delay="150">
                                    <h3 className="font-bold">¡Síguenos!</h3>
                                    <br/>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone}/><a href="tel:4424559488"
                                                                            className="ml-10">442-4559488</a>
                                    </p>
                                    <br/>
                                    <p>
                                        <FontAwesomeIcon icon={faEnvelope}/><a
                                        href="mailto:contacto@colegiodepediatrasdequeretaro.com.mx"
                                        className="-ml-4 sm:ml-10 lg:ml-0 lg:ml-5 xl:ml-10 text-sm lg:text-md xl:text-lg">contacto@colegiodepediatrasdequeretaro.com</a>
                                    </p>
                                    <br/>
                                    <p>
                                        <FontAwesomeIcon icon={faMapMarkedAlt}/><span className="ml-10">Ignacio Ramírez #10 Col. Centro Universitario, Querétaro, QRO.</span>
                                    </p>
                                    <br/>
                                    <h3 className="font-bold">Redes Sociales</h3>
                                    <br/>
                                    <p>
                                        <FontAwesomeIcon icon={faFacebook}/><a
                                        href="https://www.facebook.com/ColegioDePediatrasDeQueretaro" target="_blank"
                                        rel="noreferrer"
                                        className="ml-10">Facebook</a>
                                    </p>
                                    <br/>
                                    <p>
                                        <FontAwesomeIcon icon={faTwitter}/><a href="https://twitter.com/PediatrasDe"
                                                                              target="_blank" rel="noreferrer"
                                                                              className="ml-10">Twitter</a>
                                    </p>
                                    <br/>
                                    <p>
                                        <FontAwesomeIcon icon={faInstagram}/><a
                                        href="https://www.instagram.com/colegiodepediatrasdequeretaro/" target="_blank"
                                        rel="noreferrer"
                                        className="ml-10">Instagram</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default Contacto;