import React, {Component, Fragment} from "react";
import ReactGA from 'react-ga';
import '../assets/css/App.css';
import {Route, Switch} from 'react-router-dom';
import Navbar from "../components/navbar";
import Landing from "../pages/Landing";
import Footer from "../components/footer";
import Top from "../components/top";
import Recordings from "../pages/Recordings";
import News from "../pages/News";
import Caso from "../pages/Caso";
import Contacto from "../pages/Contacto";
import Privacy from "../pages/Privacy";
import Congreso from "../pages/Congreso";

class App extends Component {

    componentDidMount() {
        ReactGA.initialize('268983265');
    }

    render() {
        return (
            <Fragment>
                <Top/>
                <Navbar/>
                <div className="content">
                    <Switch>
                        <Route path="/" exact component={Landing}/>
                        <Route path="/privacy" exact component={Privacy}/>
                        <Route path="/recordings" exact component={Recordings}/>
                        <Route path="/congreso" exact component={Congreso}/>
                        <Route path="/news" exact component={News}/>
                        <Route path="/casos-clinicos" exact component={Caso}/>
                        <Route path="/contact" exact component={Contacto}/>
                        <Route path="/facebook" exact component={() => {
                            window.location.href = 'https://www.facebook.com/ColegioDePediatrasDeQueretaro';
                            return null;
                        }}/>
                        <Route path="/twitter" exact component={() => {
                            window.location.href = 'https://twitter.com/PediatrasDe';
                            return null;
                        }}/>
                        <Route path="/instagram" exact component={() => {
                            window.location.href = 'https://www.instagram.com/colegiodepediatrasdequeretaro/';
                            return null;
                        }}/>
                    </Switch>
                </div>
                <Footer/>
            </Fragment>
        );
    }
}

export default App;
