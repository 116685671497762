import Lele from '../../assets/img/lele.png';

export default function Historia() {
    return (
        <div id="nosotros" className="py-10 dark:bg-gray-900">
            <section className="container w-full mx-auto p-5">
                <div className="text-center font-bold text-4xl flex justify-center">
                    <div className="w-96 h-36">
                        <h2 className="section-title avenir text-gray-900 dark:text-gray-100">Nosotros</h2>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row mb-10">
                    <div className="w-full lg:w-1/3 p-2 md:p-10 flex flex-wrap content-center">
                        <div>
                            <img src={Lele} alt="Muñeca" width={300} height={350}/>
                        </div>
                    </div>
                    <div className="w-full lg:w-2/3 p-1 md:p-10">
                        <div
                            className="bg-gray-200 dark:bg-gray-700 rounded-lg leading-loose transform lg:-rotate-6 lg:scale-110 text-base">
                            <div
                                className="bg-main text-gray-50 dark:text-gray-300 py-10 px-10 lg:px-16 text-justify rounded-lg transform lg:rotate-6 lg:scale-95 shadow-lg">
                                <p>
                                    El <span className="highlight">Colegio de Pediatras de Querétaro A.C.</span>,
                                    fue fundado el día 19 de Junio del año
                                    de 1974, gracias a la iniciativa de Salvador Guerrero Servín, Enrique
                                    Cárdenas
                                    Meza, Pedro Basulto Chable, Irma Ceballos Silva, Carlos Carboney Guerrero,
                                    Luis
                                    G Concha Malo, María Elena García leal, Arturo Velázquez Soto, Julián Altube
                                    Mendieta, José Moreno Ríos, Israel Israi Durán Carranza, Isidro Claro
                                    Caballero,
                                    Fernando Almeida Caballero y Fernando Pacheco Ruíz.
                                    <br/><br/>
                                    Siendo los pioneros de la Pediátrica Queretana, uniéndose para conformar el
                                    Colegio de Pediatras del estado de Querétaro A.C., cuyo objetivos fueron:
                                </p><br/>
                                <ol className="list-decimal">
                                    <li>“Promover la investigación científica de los problemas de salud que
                                        atañen a la salud del niño”.
                                    </li>
                                    <li>“Actualizar los conocimientos pediátricos de los médicos generales,
                                        estudiantes de medicina y estudiantes de la carrera de paramédicos”.
                                    </li>
                                    <li>“Colaborar con organismos gubernamentales y privados en beneficio de la
                                        salud del niño”.
                                    </li>
                                    <li>“Fomentar las relaciones entre personas que se dedican y promueven la
                                        salud del niño”.
                                    </li>
                                    <li>”Adquirir los bienes muebles e inmuebles necesarios para el cumplimiento
                                        de los anteriores fines."
                                    </li>
                                    <li>”Otorgar los actos, celebrar los contratos y suscribir los documentos
                                        que sean antecedentes, medio o consecuencia de los anteriores fines, con
                                        capital."
                                    </li>
                                </ol>
                                <br/>
                                <p>A lo largo de estos 46 años, y gracias al esfuerzo de las mesas directivas,
                                    el Colegio de Pediatras de Querétaro A.C., ha continuado esta labor.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}