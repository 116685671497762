import EduardoAK from '../../assets/img/mesa/eduardoak.png';
import LilianEA from '../../assets/img/mesa/lilianea.png';
import MarthaGU from '../../assets/img/mesa/marthagu.png';
import JoseIM from '../../assets/img/mesa/joseim.png';
import SaturninoRS from '../../assets/img/mesa/saturninors.png';

export default function Mesa() {
    return (
        <>
            <section className="py-20 bg-white dark:bg-gray-900 block md:hidden">
                <div className="container max-w-6xl mx-auto">
                    <h2 className="text-4xl font-bold tracking-tight text-center text-gray-900 dark:text-gray-100">Mesa
                        directiva 2020 - 2022</h2>
                    <div className="grid grid-cols-4 gap-8 mt-20 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0">

                        <div
                            className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={LilianEA} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dra. Lilian
                                Esparza Anaya</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Presidenta</p>
                        </div>

                        <div
                            className="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={EduardoAK} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dr. Eduardo
                                Arias Kanemoto</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Vicepresidente</p>
                        </div>


                        <div
                            className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={MarthaGU} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dra. Martha
                                García Uribe</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Tesorera</p>
                        </div>

                        <div
                            className="flex flex-col items-center justify-between col-span-4 lg:col-span-6 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={JoseIM} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dr. José
                                Antonio Ibarra Moreno</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Secretario</p>
                        </div>

                        <div
                            className="flex flex-col items-center justify-between col-span-4 lg:col-span-6 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={SaturninoRS} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dr. Saturnino
                                Ramón Ruiz Salazar</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Segundo secretario</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-20 bg-white dark:bg-gray-900 hidden md:block">
                <div className="container max-w-6xl mx-auto">
                    <h2 className="text-4xl font-bold tracking-tight text-center text-gray-900 dark:text-gray-100">Mesa
                        directiva 2020 - 2022</h2>
                    <div className="grid grid-cols-4 gap-8 mt-20 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0">

                        <div
                            className="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={EduardoAK} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dr. Eduardo
                                Arias Kanemoto</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Vicepresidente</p>
                        </div>

                        <div
                            className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={LilianEA} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dra. Lilian
                                Esparza Anaya</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Presidenta</p>
                        </div>

                        <div
                            className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={MarthaGU} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dra. Martha
                                García Uribe</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Tesorera</p>
                        </div>

                        <div
                            className="flex flex-col items-center justify-between col-span-4 lg:col-span-6 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={JoseIM} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dr. José
                                Antonio Ibarra Moreno</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Secretario</p>
                        </div>

                        <div
                            className="flex flex-col items-center justify-between col-span-4 lg:col-span-6 px-8 py-12 space-y-4 bg-gray-100 dark:bg-gray-700 sm:rounded-xl">
                            <img src={SaturninoRS} alt="Eduardo Arias Kanemoto" width={150} height={150}
                                 className="rounded-full"/>
                            <h4 className="text-xl text-center font-medium text-gray-700 dark:text-gray-200">Dr. Saturnino
                                Ramón Ruiz Salazar</h4>
                            <p className="text-base text-center text-gray-500 dark:text-gray-300">Segundo secretario</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}