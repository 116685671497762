import React, {Component, Fragment} from "react";
import {Helmet} from 'react-helmet';
import Hero from "../components/hero";
import Nosotros from "../components/nosotros";
import Mesa from "../components/mesa";
import Historia from "../components/historia";
import Mensaje from "../components/mensaje";

class Landing extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Colegio de Pediatras de Querétaro A.C.</title>
                </Helmet>
                <Hero/>
                <Nosotros/>
                <Mesa/>
                <Historia/>
                <Mensaje/>
            </Fragment>
        );
    }
}

export default Landing;