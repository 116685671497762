import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import Patrocinadores from "../assets/img/Patrocinadores.jpeg"

class Congreso extends Component {

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Congreso | Colegio de Pediatras de Querétaro A.C.</title>
                </Helmet>
                <div>
                    <section className="container w-full mx-auto p-5">
                        <div className="text-center font-bold text-3xl flex justify-center">
                            <div className="dark:dotted-grid w-full md:w-1/2 h-36">
                                <h2 className="section-title avenir dark:text-gray-200"
                                    data-aos="fade-up">XIII Congreso de Pediatría de Querétaro</h2>
                            </div>
                        </div>
                        <div className="container">
                            <video loop autoPlay className="w-full h-full">
                                <source src="https://cdn.colegiodepediatrasdequeretaro.com/patrocinadores_todos.mp4"
                                        type="video/mp4"/>
                            </video>
                        </div>
                        <div className="container mx-auto mt-10">
                            <h3 className="dark:text-white font-semibold text-lg mb-5">Programa</h3>
                            <div
                                className="bg-gray-100 dark:bg-gray-800 mx-auto rounded shadow p-2 md:p-4 leading-relaxed text-justify"
                                data-aos="fade-up" data-aos-delay="50">
                                <iframe
                                    src="https://cdn.colegiodepediatrasdequeretaro.com/PROGRAMA%20XIII%20CONGRESO%20PEDIATRIA.pdf"
                                    className="min-h-screen overflow-y-scroll" frameBorder="0" width="100%"
                                    height=""
                                    title="Caso Clínico Abril 2021"/>
                            </div>
                        </div>
                        <div className="container">
                            <video loop autoPlay className="w-full h-full">
                                <source src="https://cdn.colegiodepediatrasdequeretaro.com/patrocinadores.mp4"
                                        type="video/mp4"/>
                            </video>
                        </div>
                        <div className="container mx-auto text-center mt-10">
                            <img src={Patrocinadores}
                                 alt="Patrocinadores oficiales - XIII Congreso de Pediatría de Querétaro"
                                 className="mx-auto"/>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default Congreso;