import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons/faFileDownload";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Caso extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Casos Clínicos | Colegio de Pediatras de Querétaro A.C.</title>
                </Helmet>
                <div id="mensaje" className="my-10 md:my-20">
                    <section className="w-full mx-auto">
                        <div className="text-center font-bold text-3xl flex justify-center mb-10">
                            <div className="w-full md:w-1/2 h-36">
                                <h2 className="section-title avenir dark:text-gray-200" data-aos="fade-up">Casos Clínicos</h2>
                            </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-row dark:text-gray-300">
                            <div className="w-full md:w-9/12">
                                <div className="w-11/12 mx-auto">
                                    <div className="text-left font-semibold py-5 h-32">
                                        <h3 className="font-semibold text-2xl mb-4 avenir">Caso Clínico #1 | Abril
                                            2021</h3>
                                        <a href="http://colegiodepediatrasdequeretaro.com.mx/casos/casoclinicoabril.pdf"
                                           className="hover:underline hover:text-main"><h2 className="text-sm"
                                                                                           data-aos="fade-up">Descargar
                                            archivo <FontAwesomeIcon
                                                icon={faFileDownload} className="font-light ml-2"/></h2></a>
                                    </div>
                                    <div
                                        className="bg-gray-100 dark:bg-gray-800 mx-auto rounded shadow p-2 md:p-4 leading-relaxed text-justify"
                                        data-aos="fade-up" data-aos-delay="50">
                                        <iframe
                                            src="http://colegiodepediatrasdequeretaro.com.mx/casos/casoclinicoabril.pdf"
                                            className="min-h-screen overflow-y-scroll" frameBorder="0" width="100%"
                                            height=""
                                            title="Caso Clínico Abril 2021"/>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-3/12 overflow-hidden">
                                <div
                                    className="w-11/12 mx-auto md:mx-0 bg-gray-100 dark:bg-gray-800 shadow rounded-lg px-2 py-6 mt-14 md:mt-32">
                                    <div className="head text-center">
                                        <h3 className="text-xl font-semibold avenir">Historial de casos</h3>
                                    </div>
                                    <hr className="my-6"/>
                                    <div className="content px-0">
                                        <a href="http://colegiodepediatrasdequeretaro.com.mx/casos/casoclinicoabril.pdf">
                                            <div
                                                className="w-full hover:bg-main rounded-lg py-2 hover:text-white">
                                                <p className="font-semibold px-5">
                                                    <FontAwesomeIcon
                                                        icon={faExternalLinkAlt} size="xs" className="font-light mr-2"/>Abril
                                                    2021</p>
                                            </div>
                                        </a>
                                    </div>
                                    <hr className="my-6"/>
                                    <div className="footer text-center">
                                        <p className="text-xs text-gray-700 dark:text-gray-300">Actualizado a la fecha: Abril 2021</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default Caso;