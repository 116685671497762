import React, {useState} from "react";
import {Link} from "react-router-dom";
import Logo from '../../assets/img/logo.png';

export default function Navbar() {
    const [open, setOpen] = useState(false);

    return (
        <section className="w-full pb-10 px-6 antialiased bg-white dark:bg-gray-900">
            <div className="mx-auto max-w-7xl">
                <nav className="relative z-50 h-24 select-none">
                    <div
                        className="container relative flex flex-wrap items-center justify-between h-24 mx-auto overflow-hidden font-medium border-b border-gray-200 dark:border-gray-700 md:overflow-visible lg:justify-center sm:px-4 md:px-2">
                        <div className="flex items-center justify-start w-1/4 h-full pr-4">
                            <Link to="/"
                                  className={(open ? "hidden" : "block") + " inline-block py-4 md:py-0 mt-2 sm:mt-8 md:mt-18 lg:mt-20 z-50"}>
                                <img src={Logo} alt="Colegio de Pediatras de Querétaro" width={150}
                                     height={120}/>
                            </Link>
                        </div>
                        <div
                            className={(open ? "flex fixed" : "hidden") + " top-0 left-0 items-start w-full h-full p-4 text-sm bg-gray-900 bg-opacity-50 md:items-center md:w-3/4 md:absolute lg:text-base md:bg-transparent md:p-0 md:relative md:flex"}>
                            <div
                                className="flex-col w-full h-auto overflow-hidden bg-white dark:bg-gray-800 py-5 md:py-0 rounded-lg md:bg-transparent md:overflow-visible md:rounded-none md:relative md:flex md:flex-row">
                                <div
                                    className="flex flex-col items-start justify-center w-full space-x-6 text-center lg:space-x-8 md:w-2/3 md:mt-0 md:flex-row md:items-center md:ml-auto">
                                    <Link to="/" onClick={() => setOpen(false)}
                                          className="inline-block w-full py-2 mx-0 ml-6 font-medium text-left text-blue-600 md:ml-0 md:w-auto md:px-0 md:mx-2 lg:mx-3 md:text-center">
                                        Inicio
                                    </Link>
                                    <Link to="/news" onClick={() => setOpen(false)}
                                          className="inline-block w-full py-2 mx-0 font-medium text-left text-gray-700 dark:text-gray-300 md:w-auto md:px-0 md:mx-2 hover:text-blue-600 lg:mx-3 md:text-center">
                                        Noticias
                                    </Link>
                                    <Link to="/casos-clinicos" onClick={() => setOpen(false)}
                                          className="inline-block w-full py-2 mx-0 font-medium text-left text-gray-700 dark:text-gray-300 md:w-auto md:px-0 md:mx-2 hover:text-blue-600 lg:mx-3 md:text-center">
                                        Casos Clínicos
                                    </Link>
                                    <Link to="/contact" onClick={() => setOpen(false)}
                                          className="inline-block w-full py-2 mx-0 font-medium text-left text-gray-700 dark:text-gray-300 md:w-auto md:px-0 md:mx-2 hover:text-blue-600 lg:mx-3 md:text-center">
                                        Contacto
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => {
                            setOpen(!open)
                        }}
                             className="absolute right-0 flex flex-col items-center items-end justify-center w-10 h-10 bg-white dark:bg-gray-700 rounded-full cursor-pointer md:hidden hover:bg-gray-100 dark:hover:bg-gray-800">
                            <svg className={(open ? "hidden" : "block") + " w-6 h-6 text-gray-700 dark:text-gray-300"}
                                 fill="none"
                                 strokeLinecap="round"
                                 strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                            <svg className={(open ? "block" : "hidden") + " w-6 h-6 text-gray-700 dark:text-gray-300"}
                                 fill="none"
                                 stroke="currentColor"
                                 viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </div>
                    </div>
                </nav>
            </div>
        </section>
    );
}