export default function Hero() {
    return (
        <div className="flex flex-wrap content-center dark:bg-gray-900">
            <div className="container max-w-lg px-4 py-32 mx-auto text-left md:max-w-5xl md:text-center">
                <div
                    className="mb-5 md:mb-12text-5xl font-bold leading-10 tracking-tight text-left text-gray-800 dark:text-gray-200 md:text-center sm:leading-none md:text-5xl lg:text-5xl text-4xl">
                    - Bienvenido -
                </div>
                <h1 className="text-5xl font-extrabold leading-10 tracking-tight text-left text-gray-900 dark:text-gray-50 md:text-center sm:leading-none md:text-6xl lg:text-7xl">
                    <span className="inline md:block">Colegio de <span
                        className="relative mt-2 text-transparent bg-clip-text bg-gradient-to-br from-blue-700 to-blue-600 md:inline-block">Pediatras</span> de <span
                        className="relative mt-2 text-transparent bg-clip-text bg-gradient-to-br from-blue-700 to-blue-600 md:inline-block">Querétaro</span> A.C.</span>
                </h1>
                <div
                    className="mx-auto mt-5 text-gray-500 dark:text-gray-300 md:mt-12 md:max-w-lg md:text-center lg:text-lg italic">
                    "Los pediatras a favor de la niñez queretana"
                </div>
            </div>
        </div>
    );
}