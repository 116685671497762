import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";

class Recordings extends Component {

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Grabaciones | Colegio de Pediatras de Querétaro A.C.</title>
                </Helmet>
                <div>
                    <section className="container w-full mx-auto p-5">
                        <div className="text-center font-bold text-3xl flex justify-center">
                            <div className="dark:dotted-grid w-full md:w-1/2 h-36">
                                <h2 className="section-title avenir dark:text-gray-200"
                                    data-aos="fade-up">Grabaciones</h2>
                            </div>
                        </div>
                        <div className="container mx-auto">
                            <h3 className="dark:text-white font-semibold text-lg mb-5">Dermatosis Neonatal (18/11/2021 - Dra.
                                Angélica Beirana)</h3>
                            <video autoPlay controls>
                                <source
                                    src="https://cdn.colegiodepediatrasdequeretaro.com/recordings/Dermatosis%20Neonatal.mp4"
                                    type="video/mp4"/>
                                Lo sentimos, tu navegador no soporta el formato de video.
                            </video>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default Recordings;