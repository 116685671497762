import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import $ from 'jquery';

class Privacy extends Component {

    componentDidMount() {
        $(document).ready(() => {
            $("html, body").animate({
                scrollTop: 0
            }, 360);
        });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Aviso de Privacidad | Colegio de Pediatras de Querétaro A.C.</title>
                </Helmet>
                <div className="w-full text-center font-montserrat mt-16 mb-10 md:mb-16 dark:text-gray-200">
                    <h2 className="font-bold text-3xl md:text-4xl">Aviso de Privacidad</h2>
                    <h4 className="font-medium text-md md:text-lg mt-5">Colegio de Pediatras de Querétaro A.C.</h4>
                </div>
                <div
                    className="w-10/12 bg-gray-100 dark:bg-gray-800 dark:text-gray-300 mx-auto rounded-lg shadow py-4 px-4 md:py-8 md:px-16 leading-relaxed text-justify">
                    <p><b>AVISO DE PRIVACIDAD COLEGIO DE PEDIATRAS DE QUERÉTARO</b> <br/><br/>

                        Por medio de nuestra Política de Privacidad le ponemos al tanto de las distintas formas en que
                        el Colegio de Pediatras de Querétaro A.C. (en adelante, “nosotros”) y nuestros sitios afiliados
                        (en adelante, “sitio web”) maneja la información que usted nos provee mientras navega en ellos.
                        <br/><br/>
                        La utilización de éstos implica su aceptación plena y sin reservas a todas y cada una de las
                        disposiciones incluidas en este Aviso Legal, por lo que, si usted no está de acuerdo con
                        cualquiera de las condiciones expuestas, no deberá hacer uso ni acceder a este sitio. Nos
                        reservamos el derecho a modificar esta Declaración de Privacidad en cualquier momento. Su uso
                        continuo de cualquier porción de este sitio tras la notificación o anuncio de tales
                        modificaciones constituirá su aceptación de tales cambios.
                        <br/><br/>
                        <b>Galletas o Cookies</b><br/><br/>
                        Este sitio hace uso de cookies, las cuales son pequeños ficheros de datos que se generan en su
                        ordenador que envían información, pero sin proporcionar referencias que permitan deducir datos
                        personales del visitante. Usted podrá configurar su navegador para que notifique y rechace la
                        instalación de las cookies enviadas por este sitio, sin que ello perjudique la posibilidad de
                        acceder a los contenidos. Sin embargo, la des activación de las mismas podría impedir el buen
                        funcionamiento del sitio.
                        <br/><br/>
                        <b>Política de privacidad de fuentes de rastreo utilizadas en este sitio</b><br/><br/>
                        Nosotros estudiamos las preferencias de nuestros usuarios, sus características demográficas, sus
                        patrones de tráfico, y otra información en conjunto para comprender mejor quiénes constituyen
                        nuestra audiencia y qué es lo que usted necesita. El rastreo de las preferencias de nuestros
                        usuarios también nos ayuda a servirle a usted los avisos publicitarios más relevantes. Para ello
                        utilizamos el sistema Google Analytics (Política de Privacidad) el cual puede almacenar cookies
                        en su computadora para ayudarnos a recopilar información que será utilizada únicamente con fines
                        estadísticos y de los comportamientos de los visitantes al utilizar nuestro sitio web. La
                        información que recopila la cookie es almacenada y transmitida hacia los servidores de Google en
                        Estados Unidos el cual utilizará dichos datos para recopilar su actividad en nuestro sitio y
                        generar informes con el propósito de identificar el uso que hace de él. Google podrá
                        proporcionar dicha información a terceros cuando así lo requiera la legislación vigente. Tal
                        como indica el apartado Galletas o Cookies de este documento. Usted puede configurar su
                        navegador para rechazar el almacenamiento de las cookies en su ordenador, sin embargo, esto
                        puede afectar el buen funcionamiento de nuestro sitio.
                        <br/><br/>
                        <b>Política de protección de datos personales</b><br/><br/>
                        Para utilizar algunos de los servicios o acceder a determinados contenidos, deberá proporcionar
                        previamente ciertos datos de carácter personal que no son obligatorios a menos que se indique lo
                        contrario y que son requeridos para poder prestarle el servicio o acceder al contenido. Escala
                        espacios podrá utilizar esta información para propósitos estadísticos o de estudio del
                        comportamiento de los visitantes en nuestros sitios. Como principio general, no compartimos ni
                        revelamos la información obtenida, excepto cuando haya sido autorizada por usted, o en los
                        siguientes casos: <br/><br/>
                        a) Cuando le sea requerido por una autoridad competente y previo el cumplimiento del trámite
                        legal correspondiente. <br/><br/>
                        b) Cuando a juicio de este sitio sea necesario para hacer cumplir las condiciones de uso y demás
                        términos de esta página, o para salvaguardar la integridad de los demás usuarios o del sitio.
                        <br/><br/>
                        Escala espacios como responsable del tratamiento y destrucción total de los datos que le sean
                        proporcionados, garantiza el buen uso, protección y confidencialidad de estos a través de las
                        medidas de seguridad y políticas internas de la empresa. El presente aviso de privacidad podrá
                        ser modificado en cualquier momento para dar cumplimiento con la legislación aplicable.
                        <br/><br/>
                        <b>MEDIOS PARA REVOCAR CONSENTIMIENTO</b><br/><br/>
                        En cualquier momento puede solicitar la revocación del consentimiento otorgado al Colegio de
                        Pediatras de Querétaro A.C. para tratar sus datos personales enviando un correo electrónico a <a
                            href="mailto:contacto@colegiodepediatrasdequeretaro.com.mx"
                            className="hover:underline text-blue-600">contacto@colegiodepediatrasdequeretaro.com.mx</a>
                        <br/>
                        Conducta Responsable
                        Toda la información que facilite deberá ser veraz. A estos efectos, usted garantiza la
                        autenticidad de todos aquellos datos que comunique como consecuencia de la cumplimentación de
                        los formularios necesarios para la suscripción de los Servicios, acceso a contenidos o áreas
                        restringidas del sitio. En todo caso usted será el único responsable de las manifestaciones
                        falsas o inexactas que realice y de los perjuicios que cause a este sitio o a terceros por la
                        información que facilite. <br/>
                        <br/>
                        Usted se compromete a actuar en forma responsable en este sitio y a tratar a otros visitantes
                        con respeto. <br/> <br/>
                        <b>Contacto</b>
                        <br/><br/>
                        Si tiene preguntas o comentarios sobre esta Política, no dude en comunicarse en cualquier
                        momento a través del formulario de contacto disponible en el sitio.</p>
                </div>
            </Fragment>
        );
    }
}

export default Privacy;