import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import axios from 'axios';
import pic1 from '../assets/img/news/invitacion1.jpeg';

class News extends Component {

    MESES = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sessions: []
        };
    }

    componentDidMount() {
        this.fetchSessions();
    }

    async fetchSessionsAsync() {
        try {
            this.setState({...this.state, isLoading: true});
            await axios.get('https://management.colegiodepediatrasdequeretaro.com/api/v1/sessions/latest').then(res => {
                const sessions = res.data.sessions;
                this.setState({sessions: sessions, isLoading: false});
            });
        } catch (e) {
            console.error('[API]', e);
            this.setState({...this.state, isLoading: false});
        }
    }

    fetchSessions = this.fetchSessionsAsync;

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Noticias | Colegio de Pediatras de Querétaro A.C.</title>
                </Helmet>
                <div id="news" className="mt-10 md:mt-0">
                    <section className="container w-full mx-auto p-5">
                        <div className="text-center font-bold text-3xl flex justify-center">
                            <div className="w-full md:w-1/2 h-36">
                                <h2 className="section-title avenir dark:text-gray-200" data-aos="fade-up">Noticias</h2>
                            </div>
                        </div>
                        <div
                            className="w-11/12 mx-auto my-5 py-4 px-4 md:py-4 md:px-12 leading-relaxed text-justify dark:text-gray-300"
                            data-aos="fade-up" data-aos-delay="50">
                            {this.state.isLoading && <div className="w-full text-center mb-10">
                                <h4 className="font-semibold text-2xl">Cargando próximas sesiones...</h4>
                            </div>}
                            <hr/>
                            {this.state.sessions?.map(
                                session => <div key={session.id}>
                                    <div className="w-full flex flex-col md:flex-row my-10">
                                        <div className="mx-auto text-center">
                                            <h3 className="font-bold text-3xl">Sesión académica virtual
                                                - {this.MESES[session.date.split(' ')[0].split('-')[1] - 1]} {new Date().getFullYear()}</h3>
                                            <p className="text-xl my-5">"{session.name}"</p>
                                            <p className="text-lg mb-5">ponente <i
                                                className="capitalize">{session.presenter.prefix}. {session.presenter.first_name} {session.presenter.last_name}</i>
                                            </p>
                                            <p className="text-lg">Día <span
                                                className="font-bold">{session.date.split(' ')[0].split('-')[2]} de {this.MESES[session.date.split(' ')[0].split('-')[1] - 1]}</span> a
                                                las <span
                                                    className="font-bold">{session.date.split(' ')[1].split(':')[0]}:{session.date.split(' ')[1].split(':')[1]}</span> hrs.
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="mb-2"/>
                                </div>
                            )}
                            <br/>
                            <div className="text-center mb-10">
                                <p>Favor de contestar el siguiente cuestionario para esta sesión:</p>
                                <a href="https://forms.gle/RsQkcCtFDTEVkeYAA" target="_blank" rel="noreferrer" className="link">https://forms.gle/RsQkcCtFDTEVkeYAA</a>
                            </div>
                            <img src={pic1} alt="Invitación sesión" className="mx-auto"/>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default News;