import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './app/App';
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
    <Router>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </Router>,
    document.getElementById('root')
);
