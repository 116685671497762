import Queretaro from '../../assets/img/queretaro.jpg';

export default function Nosotros() {
    return (
        <section className="px-2 py-32 bg-white dark:bg-gray-900 md:px-0" >
            <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
                <div className="flex flex-wrap items-center sm:-mx-3">
                    <div className="w-full md:w-1/2 md:px-3">
                        <div
                            className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                                <span className="block xl:inline">¿Quiénes somos?</span>
                            </h1>
                            <p className="mx-auto text-base text-justify text-gray-500 dark:text-gray-300 sm:max-w-md lg:text-xl md:max-w-3xl">Somos
                                un grupo de profesionales de la medicina, con la especialidad y subespecialidad en
                                la Pediatría, que estamos agrupados y unidos como un colegio de Pediatras.</p>
                            <p className="mx-auto text-base text-justify text-gray-500 dark:text-gray-300 sm:max-w-md lg:text-xl md:max-w-3xl">
                                <b>Nuestra misión es mantener una:</b><br/><br/>
                                Actualización médica continúa en el arte y la ciencia de la pediatría. Compartir y
                                difundir el conocimiento médico y científico.
                                <br/>
                                Aplicar este conocimiento para mejorar la salud de los niños y niñas en el estado de
                                Querétaro
                            </p>
                            <p className="mx-auto text-base text-justify text-gray-500 dark:text-gray-300 sm:max-w-md lg:text-xl md:max-w-3xl">
                                <b>Nuestros Valores</b><br/><br/>
                                Respeto, responsabilidad, honestidad, compromiso y bondad.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl mb-16">
                            <img src={Queretaro} alt="Querétaro" />
                        </div>
                        <p className="mx-auto text-base text-justify text-gray-500 dark:text-gray-300 sm:max-w-md lg:text-xl md:max-w-3xl">
                            <b>Nuestra visión</b> ¿Qué es lo que seremos?<br/><br/>
                            Un colegio unido e incluyente al ingreso de nuevos colegiados, que fomenta la
                            participación de todos los pediatras colegiados, en reuniones académicas y
                            sociales. <br/><br/>
                            Ser un colegio reconocido a nivel local, regional y nacional, por su calidad académica;
                            su comportamiento ético y humano, por su enseñanza, su investigación y mejorar la
                            asistencia de los niños y niñas queretan@s.
                        </p><br/>
                    </div>
                </div>
            </div>
        </section>
    );
}