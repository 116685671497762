import mensaje1 from "../../assets/img/mensaje/mensaje00001.jpg";
import mensaje2 from "../../assets/img/mensaje/mensaje00002.jpg";
import mensaje3 from "../../assets/img/mensaje/mensaje00003.jpg";

export default function Mensaje() {
    return (
        <div id="mensaje" className="mt-10">
            <section className="container w-full mx-auto p-5">
                <div className="text-center font-bold text-3xl flex justify-center">
                    <div className="dark:dotted-grid w-full md:w-1/2 h-36">
                        <h2 className="section-title avenir dark:text-gray-200" data-aos="fade-up">Mensaje de la Presidenta</h2>
                    </div>
                </div>
                <div className="grid lg:gap-4 grid-flow-col auto-rows-max p-2 md:p-5 lg:p-10 my-10">
                    <div>
                        <img src={mensaje1} alt="Img1"
                             className="lg:max-h-64 lg:rounded-lg mx-auto transform lg:-rotate-2" data-aos="fade-left"
                             data-aos-delay="100"/>
                    </div>
                    <div>
                        <img src={mensaje3} alt="Img2"
                             className="lg:max-h-64 lg:rounded-lg mx-auto transform lg:scale-110 lg:rotate-3"
                             data-aos="fade-up" data-aos-delay="50"/>
                    </div>
                    <div>
                        <img src={mensaje2} alt="Img3"
                             className="lg:max-h-64 lg:rounded-lg mx-auto transform lg:scale-95 lg:-rotate-2"
                             data-aos="fade-right" data-aos-delay="150"/>
                    </div>
                </div>
                <div
                    className="w-10/12 bg-gray-100 dark:bg-gray-800 dark:text-white mx-auto rounded shadow py-4 px-4 md:py-8 md:px-12 leading-relaxed text-justify"
                    data-aos="fade-up" data-aos-delay="50">
                    <p>
                        Estimados colegiados.
                        <br/><br/>
                        Agradezco la oportunidad que me han dado al elegirme como presidenta del Colegio de
                        Pediatras de Querétaro AC, responsabilidad que asumo con gran entusiasmo y compromiso;
                        dedicaré mi
                        esfuerzo y tiempo, para que el Colegio de Pediatras, siga manteniéndose unido, y que
                        tenga a nivel local y nacional, siendo un Colegio digno de cada uno de sus
                        representados.
                        <br/><br/>
                        Sé que a través de los 45 años de vida de la sociedad, grandes hombres y mujeres,
                        mediante, su esfuerzo, trabajo constante y la dedicación, han podido ser parte de los
                        desafíos que en salud del estado de Querétaro han enfrentado.
                        <br/><br/>
                        Las acciones de cada uno de ustedes y su participación en la educación, investigación y
                        el desarrollo de la Pediatría en nuestro Estado, han contribuido a que nuestro Colegio,
                        sea considerado como un apoyo y un pilar en la salud pública de los niños y niñas
                        queretanos.
                        <br/><br/>
                        Es para mí un compromiso, el poder continuar , los pasos y los esfuerzos de las mesas
                        directivas y los presidentes que me antecedieron, pues fueron ellos y ellas, quienes
                        dieron cimiento y estructura a nuestro colegio; el mundo actualmente enfrenta a una
                        Pandemia, por el virus del Sars Cov 2 ( Coronavirus) , lo que representa un desafío,
                        para nuestra profesión y para nuestro colegio, ante este nuevo paradigma, y ante el
                        distanciamiento social, ha sido importante la incorporación de nuevas tecnologías,
                        informáticas y de comunicación, por lo que se realizan reuniones académicas, virtuales,
                        mediante video conferencias. Esta oportunidad de transmitir nuevos conocimientos en
                        tiempo real, nos ayuda a estudiar, diagnosticar y tratar a nuestros pacientes.
                        <br/><br/>
                        Estoy convencida de que esta tarea no es fácil, para ello, he conformado un equipo de
                        trabajo con amplia experiencia, agregando nuevo talento joven, con gran trayectoria y
                        con decidida voluntad para trabajar unidos y en pro de nuestro colegio.
                        <br/><br/>
                        Este equipo no solamente simboliza el trabajo continuo, sino también la unidad y la
                        convicción de que juntos somos más fuertes y de que unidos lograremos más.
                        <br/><br/>
                        Está filosofía de unidad deberá permear a cada uno de nosotros, ya que, son ustedes
                        quienes hacen grande al colegio.
                        Los invito a permanecer unidos a trabajaren nuestra educación médica continua y en
                        nuestras relaciones personales y sociales. Y a vincular la educación médica con la
                        práctica clínica, pues es ahí donde se ve reflejado todo nuestro estudio, en la calidad
                        de atención de nuestros pacientes.
                        <br/><br/>
                        Les informo que uno de los ejes principales de esta administración será la educación
                        médica continua, al poder incorporar a nuestros colegiados con las nuevas tecnologías de
                        la información, con el objetivo de impulsar el desarrollo profesional.
                        <br/><br/>
                        Para ello, se ha conformado una plataforma digital (web), que permitirá el establecer el
                        contacto diario y será un espacio abierto para que cada uno de los colegiados pueda
                        sembrar su conocimiento y difundir toda aquella experiencia, que lo ha hecho progresar
                        en la práctica médica diaria.
                        <br/><br/>
                        Necesitamos conocerlos a todos, cada uno puede aportar mucho y la suma de esto,
                        contribuirá al engrandecimiento de nuestro querido Colegio.
                        <br/><br/>
                        Reconozco que se requiere más, por eso se contempla que la mesa directiva del colegio
                        sea más cercana a sus miembros.
                        El colegio funcionara con un Plan Estratégico con cinco ejes rectores que son:
                        <br/><br/>
                        1. La intercomunicación y vinculación interna y la proyección externa.<br/>
                        2. La educación médica continúa.<br/>
                        3. El desarrollo, innovación y tecnología de proyectos aplicados a la Pediatría.<br/>
                        4. La administración efectiva.<br/>
                        5. La vinculación del Colegio con las Universidades y con los hospitales públicos y
                        privados del Estado de Querétaro.<br/>
                        <br/><br/>
                        Las nuevas generaciones de pediatras son el motor, por ello también estarán contemplados en
                        nuestro Colegio, pues son la semilla que algún día dará grandes frutos y seguirá los pasos
                        de los destacados médicos que los
                        antecedieron. En el futuro, visualizo un Colegio más fuerte, unido, competitivo.
                        <br/><br/>
                        Agradezco su confianza, y les reitero mi compromiso, mi trabajo y mi esfuerzo y solicito
                        su apoyo en el beneficio colectivo, ya que al sumar todos hacemos más grande a nuestro
                        Colegio y a nuestro estado de Querétaro.
                    </p>
                    <br/>
                    <p className="text-center">
                        Dra. Lilian Esparza Anaya.<br/>
                        Presidenta
                    </p>
                </div>
            </section>
        </div>
    );
}