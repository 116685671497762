import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons/faArrowUp";
import $ from 'jquery';

class Top extends Component {
    componentDidMount() {
        $(document).ready(() => {
            $("#goTopBtn").hide();
            $(window).scroll(() => {
                if ($(window).scrollTop() > 150) {
                    $("#goTopBtn").fadeIn();
                } else {
                    $("#goTopBtn").fadeOut();
                }
            });

            $("#goTopBtn").click(() => {
                $("html, body").animate({
                    scrollTop: 0
                }, 360);
                return false;
            });
        });
    }

    render() {
        return (
            <div
                className="fixed w-14 h-14 md:w-18 md:h-18 bg-blue-800 bottom-10 right-10 z-50 rounded shadow cursor-pointer text-white flex flex-wrap content-center"
                id="goTopBtn">
                <div className="w-full text-center">
                    <FontAwesomeIcon icon={faArrowUp} size="lg"/>
                </div>
            </div>
        );
    }
}

export default Top;